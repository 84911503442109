.fadeInRight {
   opacity: 0;
   animation: fadeInRight 1.5s forwards;
}

@keyframes fadeInRight {
   from {
      opacity: 0;
      transform: translateX(40px);
   }
   to {
      opacity: 1;
      transform: translateX(0);
   }
}

.fadeInLeft {
   opacity: 0;
   animation: fadeInLeft 1.5s forwards;
}

@keyframes fadeInLeft {
   from {
      opacity: 0;
      transform: translateX(-40px);
   }
   to {
      opacity: 1;
      transform: translateX(0);
   }
}

.fadeIn {
   animation: fadeIn 1.5s forwards;
}

@keyframes fadeIn {
   from {
      opacity: 0;
   }
   to {
      opacity: 1;
   }
}

.fadeOut {
    animation: fadeOut 1.5s forwards;
 }
 
 @keyframes fadeOut {
    from {
       opacity: 1;
    }
    to {
       opacity: 0;
    }
 }




 .fadeInRight2 {
    animation: fadeInRight2 1.5s forwards;
 }
 
 @keyframes fadeInRight2 {
    from {
       transform: translateX(40px);
    }
    to {
       transform: translateX(0);
    }
 }